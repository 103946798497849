import forList from "@/mixin/forList";

export default {
    name: "RoleList",
    mixins: [ forList ],

    data() {
        return {
            SP_: {
                search_name: "",
            },
			tableData: []
        };
    },

    methods: {
		fetchData() {
			if (this.loading) return;
			this.loading = true;

			this.$oxios({
				url: "/api/role/getRoleList",
				method: "post",
				data: {...this.extractParams()}
			})
			.then(res => {
				if (res.code === 2000 && res.data.data) {
					this.total = res.data.count;
					const list = res.data.data;
					this.tableData = list;

				} else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
		},

		// 点击 "新增菜单"
		handleAddBtn() {
			this.$router.push({
				path: "/basic/roles/add",
				query: {sys_id: this.SP_.search_sys}
			});
		},

		// 点击表格行操作按钮: 编辑 
		handleEdit(data) {
			this.$router.push({
				path: `/basic/roles/edit/${data.id || 5}`,
				query: {sys_id: data.sys_id}
			});
		},

		// 点击表格行操作按钮: 删除
		async handleDelete(data) {
			let loadingRef;
			try {
				await this.$confirm("确定要删除吗", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/role/deleteRole",
					method: "post",
					data: { roleid : data.id }
				})

				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "删除失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		},
    },

	created () {
		this.auth.save = this.$hasAuthFor("api/role/saveRole");
		// this.auth.detail = this.$hasAuthFor("api/role/getRoleInfo");
		this.auth.delete = this.$hasAuthFor("api/role/deleteRole");
	}
};