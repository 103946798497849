import forList from "@/mixin/forList";
import PostEdit from "./PostEdit.vue";
import {initialPB, pageTips} from "./config";

export default {
	name: "PostList",
	mixins: [forList],
	components: {PostEdit},

	data() {
		return {
			pageTips,          // 固定的，不用追踪
			directionEnum: [], // 业务方向选项
			roleEnum:      [], // 角色选项
			SP_: {
				searchName: "",
				searchRoleName: "",
				searchDir : "",
			},
			editingItem: null
		};
	},

	methods: {

		async fetchData() {
			const loading = this.$loading();

			// this.fetchDirectionEnum();
			// this.fetchRoleEnum();
			try {
				const res = await this.$oxios({
					url: "/api/post/getList",
					method: "post",
					data:this.extractParams()
				});

				if (res.code === 2000) {
					this.total = res.data.count;
					const list = res.data.postlist;
					// list.forEach(it => {
					// 	it.positionIds = it.position_ids.split(",").map(
					// 		it => parseInt(it)
					// 	).filter(Boolean)
					// });
					this.tableData = list;
					
					if (!this.directionEnum.fine) { // 节约性能
						const directionEnum = res.data.PostDestTypeEnum;
						directionEnum.fine = true;
						this.directionEnum = directionEnum;
					}

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();	
			}
		},

		// 获取配置参数
		// async fetchDirectionEnum () {
		// 	if (this.directionEnum.fine) return;
		// 	try {
		// 		const res = await this.$axios({
		// 			url: "/api/configuration/queryTypeParam",
		// 			method: "post"
		// 		})
		// 		if (res.code === 2000) {
		// 			const list = res.data.CompanyTypeEnum;
		// 			list.fine = true;
		// 			this.directionEnum = list;

		// 		} else if (res.code !== 1003)
		// 			throw res
				
		// 	} catch (reason) {
		// 		console.warn("获取选项参数出错", reason);
		// 	}
		// },
		// async fetchRoleEnum () {
		// 	if (this.roleEnum.fine) return;
		// 	try {
		// 		const res = await this.$axios({
		// 			url: "/api/configuration/queryTypeParam",
		// 			method: "post"
		// 		})
		// 		if (res.code === 2000) {
		// 			const list = res.data.CompanyTypeEnum;
		// 			list.fine = true;
		// 			this.roleEnum = list;

		// 		} else if (res.code !== 1003)
		// 			throw res
				
		// 	} catch (reason) {
		// 		console.warn("获取选项参数出错", reason);
		// 	}
		// },

		// 点击 "新增菜单"
		handleAddBtn() {
			this.editingItem = initialPB();
		},

		// 点击表格行操作按钮: 编辑 
		handleEdit(data) {
			this.editingItem = data;
		},

		// 点击表格行操作按钮: 删除
		async handleDelete(data) {
			let loadingRef;
			try {
				await this.$confirm("确定要删除吗", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/post/deletePost",
					method: "post",
					data: {
						postid: parseInt(data.id)
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "删除失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		}

	},

	created () {
		this.auth.save = this.$hasAuthFor("api/post/addPost");
		this.auth.delete = this.$hasAuthFor("api/post/deletePost");
	}
};