import {nanoid} from "nanoid";

export const mockEnum = [
    { name: "系统管理员", id: 1 },
    { name: "区域总监",   id: 2 },
    { name: "区域经理",   id: 3 },
    { name: "部门经理",   id: 4 }
]

// 函数创建一个空的菜单数据
export function initialPB () {
    return {
        nanoid: nanoid(),
        name: "",
        sysId: "",
        roleId: "",
        busiDir: "",
        order: 99,
        level: 99,
        content: "",
    };
}

export const pageTips = [
    {tag: "员工", value: "41-50"},
    {tag: "主管", value: "31-40"},
    {tag: "经理", value: "21-30"},
    {tag: "总监", value: "11-20"},
    {tag: "系统管理员", value: "1-10"},
]