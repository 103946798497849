<!-- 添加、编辑字典项 -->
<template>
    <el-dialog class="config-edit"
        :visible="viewIsReady"
        @close="handleClose"
        :title="titleText"
        width="500px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="100px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="系统编号" prop="syscode">
                <el-select v-model="PB.syscode"
                    class="width3">
                    <el-option
                        v-for="it in codeEnum"
                        :key="it.value"
                        :label="it.value"
                        :value="it.value"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="状态" prop="state">
                <el-radio-group v-model="PB.state">
                    <el-radio :label="1">启用</el-radio>
                    <el-radio :label="0">禁用</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="系统名称" prop="name">
                <el-input v-model="PB.name"
                    maxlength="100"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="系统logo" prop="files">
                <UploadFile v-model="PB.files" :value='PB.files' :body="{isZip: 1,direcFile:'skinFile'}" ></UploadFile>
            </el-form-item>
            <el-form-item label="主页logo" prop="filesMain">
                <UploadFile v-model="PB.filesMain" :body="{isZip: 1,direcFile:'skinFile'}"></UploadFile>
            </el-form-item>
            <el-form-item label="登录页图片" prop="filesLogin">
                <UploadFile v-model="PB.filesLogin" :body="{isZip: 1,direcFile:'skinFile'}"></UploadFile>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import UploadFile from '../../../components/UploadFile/UploadFile.vue'
export default {
    name: 'ConfigEdit',
    components: {UploadFile},
    props: ["item", "codeEnum"],

    data () {
        return {
            viewIsReady: false,
            PB: {
                id:'',
                syscode:'',
                state:0,
                name   : "",
                files: [],
                filesMain: [],
                filesLogin: [],
            },
            rules: {
                syscode: { required: true, message: "请选择系统编号",
                    trigger: "blur" },
                name: { required: true, message: "请输入系统名称",
                    trigger: "blur" },
            },
            postList: [],
        }
    },

    computed: {
        isUpdate () {
            return !!this.item?.id
        },
        titleText () {
            return this.isUpdate ? '编辑' : '新增'
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    const {sysPath,mainPath,loginPath} = item;
                    this.PB = {
                        
                        id:item.id,
                        name:item.name,
                        syscode:item.sysCode,
                        state:item.state,
                        files  : sysPath ? [{
                            name: sysPath.slice(sysPath.lastIndexOf("/")+1),
                            url: this.$baseURL + "/" + sysPath,
                            path: sysPath
                        }] : [], // todo 
                        filesMain  : mainPath ? [{
                            name: mainPath.slice(mainPath.lastIndexOf("/")+1),
                            url: this.$baseURL + "/" + mainPath,
                            path: mainPath
                        }] : [], // todo 
                        filesLogin  : loginPath ? [{
                            name: loginPath.slice(loginPath.lastIndexOf("/")+1),
                            url: this.$baseURL + "/" + loginPath,
                            path: loginPath
                        }] : [], // todo 
                    }
                    this.viewIsReady = true;
                });
            }
        }
    },

    methods: {
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".white-list-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                // const {name, sysCode, url, outUrl, content, files} = this.PB;
                const res = await this.$axios({
                    url: "/api/configuration/saveSysIcon",
                    method: "post",
                    data: {
                        syscode:this.PB.syscode,
                        state:this.PB.state,
                        name:this.PB.name,
                        syspath:this.PB.files.map(it=>it.path).join(),
                        mainpath:this.PB.filesMain.map(it=>it.path).join(),
                        loginpath:this.PB.filesLogin.map(it=>it.path).join(),
                        id: this.PB.id
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.viewIsReady = false;
            this.PB = {
                files: []
            };
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped></style>