import forList from "@/mixin/forList";
import ConfigEdit from "./ConfigEdit.vue";
import { initialPB } from "./config";

export default {
    name: "SystemSkin",
    mixins: [forList],
    components: { ConfigEdit },
    noFetchFirst: true,

    data() {
        return {
            SP_: {
                searchState: '',
                searchValue: '',
            },
            stateArr: [
                {
                    value: 1,
                    name: '启用'
                },
                {
                    value: 0,
                    name: '禁用'
                }


            ],
            sysCodeEnum: [],
            sysTypeEnum: [],
            editingItem: null,
        };
    },

    methods: {
        async fetchData() {
            console.log("fetching...");
            const loading = this.$loading();

            try {
                console.log(this.SP_.searchValue);
                const res = await this.$axios({
                    url: "/api/configuration/querySysIcon",

                    method: "post",
                    data: {
                        ...this.extractParams(),
                        searchValue: this.SP_.searchValue
                    } 
                });

                if (res.code === 2000) {
                    this.sysCodeEnum = res.data.sysCodeEnum;
                    this.total = res.data.count;
                    const list = res.data.iconList;
                    this.tableData = list;

                    loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },

        // 点击 "新增菜单"
        handleAddBtn() {
            this.editingItem = initialPB();
        },

        // 点击表格行操作按钮: 编辑 
        handleEdit(data) {
            this.editingItem = data;
        },
    },

    created() {
        if (this.$route.params.searchValue) {
            this.SP_.searchValue = this.$route.params.searchValue
        }
        console.log(this.SP_.searchValue);
        this.fetchData()
        this.auth.save = this.$hasAuthFor("api/configuration/saveSysIcon");
    }
};