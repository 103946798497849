export const levelEnum = [
    {name: "一级", value: 1},
    {name: "二级", value: 2},
    {name: "三级", value: 3}
];

export const endEnum = [
    {name: "PC",    value: 1},
    {name: "小程序", value: 2},
    {name: "APP",   value: 4},
    {name: "WAP",   value: 3}
]

// 函数创建一个空的菜单数据
export function initialPB () {
    return {
        sysId: "",
        end  : 1,
        type: "",
        parentId: "",
        menuName: "",
        path: "",
        webPath: "",
        icon: "",
        icon1: "",
        order: 99,
        content: "",
        isHide: '0', // 是否隐藏菜单 0否 1是
    }
}