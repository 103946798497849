<!-- 添加、编辑字典项 -->
<template>
    <el-dialog class="white-list-edit"
        :visible="viewIsReady"
        @close="handleClose"
        :title="titleText"
        width="560px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="100px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="岗位名称" prop="name">
                <el-input v-model.number="PB.name"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="所属系统" prop="sysId">
                <el-select v-model="PB.sysId"
                    @change="fetchRolesBySys"
                    :disabled="isUpdate"
                    class="width3">
                    <el-option
                        v-for="level in systemEnum"
                        :key="level.id"
                        :label="level.name"
                        :value="level.id"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="角色" prop="roleId">
                <el-select v-model="PB.roleId"
                    class="width3">
                    <el-option
                        v-for="level in roleList"
                        :key="level.id"
                        :label="level.name"
                        :value="level.id"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="业务方向" prop="busiDir">
                <el-radio-group v-model="PB.busiDir">
                    <el-radio
                        v-for="item in directionEnum"
                        :key="item.value"
                        :label="item.value">
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="排序" prop="order" class="wrap">
                <el-input v-model.number="PB.order"
                    class="width3"></el-input>
                <span class="hint">数字越大显示越靠前</span>
            </el-form-item>

            <el-form-item label="岗位级别" prop="level" class="wrap">
                <el-input v-model.number="PB.level"
                    class="width3"></el-input>
                <span class="hint">
                    <i v-for="item in pageTips"
                        :key="item.value">
                        {{item.tag}}: {{item.value}}
                    </i>
                </span>
            </el-form-item>

            <el-form-item label="备注" prop="content">
                <el-input v-model="PB.content"
                    type="textarea" rows="5"
                    class="width3"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {pageTips} from "./config";
export default {
    name: 'PostEdit',
    props: ["item", "directionEnum"],

    data () {
        return {
            pageTips, // 固定的，不用追踪
            viewIsReady: false,
            PB: {
                name: "",
                sysId: "",
                roleId: "",
                busiDir: "",
                order: 99,
                level: 99,
                content: ""
            },
            // 验证规则
            rules: {
                name   : { required: true, message: "请输入名称"   , trigger: "blur" },
                sysId  : { required: true, message: "请选择所属系统", trigger: "blur" },
                roleId : { required: true, message: "请选择角色"    , trigger: "blur" },
                busiDir: { required: true, message: "请选择业务方向", trigger: "blur" },
                order  : { validator (r, val, c) {
                    if (typeof val === "number") c()
                    else if (!/~[1-9]\d?$/.test(val)) {
                        c("只能输入正整数")
                    }
                    else c()
                }, trigger: "blur"},
                level  : { validator (r, val, c) {
                    if (typeof val === "number") c()
                    else if (!/~[1-9]\d?$/.test(val)) {
                        c("只能输入正整数")
                    }
                    else c()
                }, trigger: "blur"},
            },
            roleList: [],
        }
    },

    computed: {
        isUpdate () {
            return !!this.item?.id
        },
        titleText () {
            return this.isUpdate ? '编辑岗位' : '新增岗位'
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                for (let key in item) {
                    const _key = key.toCamel();
                    this.PB[_key] = item[key] == null ? "" : item[key]
                }
                if (item.sys_id) this.fetchRolesBySys(item.sys_id);
                this.viewIsReady = true;
            }
        }
    },

    methods: {
		// 获取岗位配置参数
		async fetchConfig () {
			if (this.postList.fine) return;
			try {
				const res = await this.$axios({
					url: "/api/employee/queryPostAll",
					method: "post"
				})
				if (res.code === 2000) {
					const list = res.data.postList;
					list.fine = true;
					this.postList = list;

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取岗位配置参数出错", reason);
			}
		},


        // 根据系统查角色
        async fetchRolesBySys (sysId) {
            this.roleList = [];

            try {
                const res = await this.$axios({
                    url: "/api/role/queryRoleAll",
                    method: "post",
                    data: { sysId }
                });

				if (res.code === 2000) {
					this.roleList = res.data.roleList;

				} else if (res.code !== 1003)
					throw res
                
            } catch (reason) {
				console.warn("查询系统角色失败", reason);
            }
        },

        async handleSave () {
            const loadingRef = this.$loading({
                target: ".white-list-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const {name, sysId, roleId, busiDir, order, level, content} = this.PB;
                const res = await this.$axios({
                    url: "/api/post/addPost",
                    method: "post",
                    data: {
                        name, sysId, roleId, busiDir, order, level, content,
                        id: this.PB.id
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.viewIsReady = false;
            this.PB = {
                name: "",
                sysId: "",
                roleId: "",
                busiDir: "",
                order: 99,
                level: 99,
                content: ""
            };
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .hint {
        margin-left: 0 !important;
        color: orangered !important;
    }
    
    .el-radio-group {
        margin-bottom: -10px;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>