import { nanoid } from "nanoid";

export const mockEnum = [
    { name: "系统管理员", id: 1 },
    { name: "区域总监", id: 2 },
    { name: "区域经理", id: 3 },
    { name: "部门经理", id: 4 }
]

// 函数创建一个空的数据
export function initialPB() {
    return {
        id: '',
        syscode: '',
        state: 0,
        name: "",
        files: [],
        filesMain: [],
        filesLogin: [],
    };
}