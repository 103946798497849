<!-- 设置系统 -->
<template>
    <div class="checkbox-group">
        <el-checkbox v-model="value.checked"
            @change="handleChange1">
            {{value.name}}</el-checkbox>
        <el-checkbox v-model="value.view_state"
            >
            是否显示</el-checkbox>
        <el-checkbox v-model="value.status"
            >
            是否启用</el-checkbox>
    </div>
</template>

<script>
export default {
    name: 'CheckboxGroup',
    props: ["value"],

    // data () {
    //     return {
    //         obj: {
    //             sys_id    : "",
    //             name      : "",
    //             status    : false,
    //             view_state: false,
    //             checked   : false
    //         }
    //     }
    // },

    // watch: {
    //     value (data) {
    //         if (data) for (let key in this.obj) {
    //             this.obj[key] = data[key]
    //         }
    //     }
    // },

    // created () {
    //     for (let key in this.obj) {
    //         this.obj[key] = this.value[key]
    //     }
    // },

    methods: {
        handleChange1 (checked) {
            this.value.status =
            this.value.view_state = checked
            // console.log(e)
            // this.$emit("input", this.obj);
        }
    }
}
</script>

<style lang='scss' scoped>
    .checkbox-group {
        display: flex;
        width: 380px;
        
        .el-checkbox:first-child{
            flex-grow: 1;
        }
    }
</style>