<!-- 添加、编辑字典项 -->
<template>
    <el-dialog class="config-edit"
        :visible="viewIsReady"
        @close="handleClose"
        :title="titleText"
        width="500px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="100px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="系统编号" prop="sysCode">
                <el-select v-model="PB.sysCode"
                    class="width3">
                    <el-option
                        v-for="it in codeEnum"
                        :key="it.value"
                        :label="it.value"
                        :value="it.value"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="系统名称" prop="name">
                <el-input v-model="PB.name"
                    maxlength="100"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="访问地址" prop="url">
                <el-input v-model="PB.url"
                    maxlength="100"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="离职地址" prop="outUrl">
                <el-input v-model="PB.outUrl"
                    maxlength="100"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="系统logo" prop="files">
                <UploadFile v-model="PB.files" :body="{isZip: 1}"></UploadFile>
            </el-form-item>

            <el-form-item label="备注" prop="content">
                <el-input v-model="PB.content"
                    type="textarea" rows="5"
                    maxlength="200"
                    class="width3"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import UploadFile from '../../../components/UploadFile/UploadFile.vue'
export default {
    name: 'ConfigEdit',
    components: {UploadFile},
    props: ["item", "codeEnum"],

    data () {
        return {
            viewIsReady: false,
            PB: {
                name   : "",
                sysCode: "",
                url    : "",
                outUrl : "",
                content: "",
                files: []
            },
            rules: {
                sysCode: { required: true, message: "请选择系统编号",
                    trigger: "blur" },
                name: { required: true, message: "请输入系统名称",
                    trigger: "blur" },
                url:  { required: true, message: "请输入访问地址",
                    trigger: "blur" }
            },
            postList: [],
        }
    },

    computed: {
        isUpdate () {
            return !!this.item?.id
        },
        titleText () {
            return this.isUpdate ? '编辑路由' : '新增路由'
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                const {sys_code, name, url, out_url, icon, content} = item;
                this.PB = {
                    name, url, content,
                    files  : icon ? [{
                        name: icon.slice(icon.lastIndexOf("/")+1),
                        url: this.$baseURL + "/" + icon,
                        path: icon
                    }] : [], // todo 
                    sysCode: sys_code,
                    outUrl : out_url,
                    id     : item.id
                }
                this.viewIsReady = true;
            }
        }
    },

    methods: {
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".white-list-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const {name, sysCode, url, outUrl, content, files} = this.PB;
                const res = await this.$axios({
                    url: "/api/system/saveSystem",
                    method: "post",
                    data: {
                        name, sysCode, url, outUrl, content,
                        icon: files.map(it=>it.path).join(),
                        id: this.PB.id
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.viewIsReady = false;
            this.PB = {
                files: []
            };
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped></style>