
import forEdit from "@/mixin/forEdit";
import {endEnum} from "../menuList/config";

// 在 tree (每一级外层是一个arr) 中查找 id 为某个值的对象有没有 下级(children) 属性
function hasChildInTree(tree, id) {
    // if (!tree || !id) {
    //     console.warn("查找数有无节点",tree,id);
    //     return false;
    // }
    let result = false;
    start: {
        for (let i = 0; i < tree.length; i++) {
            const hasChild = tree[i].children instanceof Array;
            if (tree[i].id == id) {
                result = hasChild;
                break start;
            } else if (hasChild) {
                result = hasChildInTree(tree[i].children, id);
                // console.log(result, tree[i].children, id);
                if (result) break start;
            }
        }
    }
    return result;
}

export default {
    name: 'RoleEdit',
    mixins: [forEdit],

    data () {
        return {
            endEnum,
            activeName: endEnum[0].name,
            PB: {
                id: undefined, // 编辑才有id
                sysId: "",     // 所属系统
                name: "",      // 角色名称
                roleCode: "",  // 角色CODE
                content: "",   // 备注
            },

            rules: {
                sysId: {
                    required: true,
                    message: "请选择所属系统"
                },
                name: {
                    required: true,
                    message: "请填写角色名称"
                }
            },

            // 树
            tree: [],
            
            treeProps: {
                children: 'children',
                label: 'menuName'
            }
        }
    },

    computed: {
        isUpdate () {
            return !!this.$route.params?.id
        }
    },

    methods: {

        // 获取页面数据
        async fetchData(onlytree = false) {
			const loading = this.$loading();

            try {
                const res = await this.$axios({
                    url: "/api/role/getRoleInfo",
                    method: "post",
                    data: {
                        roleid: this.PB.id,
                        sysId : this.PB.sysId || undefined
                    }
                });

				if (res.code === 2000) {
                    // 更新 基础信息，没有id 也需要获取树信息
                    if (!onlytree && this.PB.id) for (let key in this.PB) {
                        this.PB[key] = res.data.role[key] || this.PB[key]
                    }
                    const {resourceSourceTypeEnum, roleResource} = res.data;
                    resourceSourceTypeEnum.forEach(end => {
                        // 更新树节点
                        if (!(end.tree && end.tree.length)) {
                            const qArr = [];
                            res.data["allresources"+end.value].forEach(resource => {
                                const index = Number(resource.type - 1);
                                if (qArr[index] === undefined || !(qArr[index] instanceof Array)) {
                                    qArr[index] = [];
                                }
                                qArr[index].push(resource);
                            });
    
                            const __len = qArr.length;
                            for (let i = __len - 1; i > 0; i--) {
                                const subArr = qArr[i];
                                const supArr = qArr[i - 1];
                                subArr.forEach(subItem => {
                                    const id = subItem.parentId = Number(subItem.parentId);
                                    for (let j = 0; j < supArr.length; j++) {
                                        const supItem = supArr[j];
                                        if (Number(supItem.id) === id) {
                                            if (supItem.children === undefined || !(supItem.children instanceof Array)) {
                                                supItem.children = []
                                            }
                                            supItem.children.push(subItem);
                                            break;
                                        }
                                    }
                                });
                            }
                            end.tree = qArr[0] || [];
                        }
    
                        // 获取的角色具有的权限列表（选中的树节点）
                        if (this.PB.id && roleResource instanceof Array && roleResource.length) {
    
                            const checkedKeys = roleResource
                                .filter(item => {
                                    const flag = hasChildInTree(end.tree, item.resource_id);
                                    return !flag;
                                })
                                .map(item => Number(item.resource_id));

                                // console.log(checkedKeys);
                                this.$refs["tree"+end.value]?.[0].setCheckedKeys(checkedKeys);
                        }
                    });
                    this.endEnum = resourceSourceTypeEnum;
                    this.activeName = this.endEnum[0].name;

					loading.close();
				} else if (res.code !== 1003)
					throw res
                
            } catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();	
            }
        },

        handleSysChange () {
            this.fetchData(true)
        },

        // 点击提交按钮
        async handleSubmitButton () {
            let loading;

            try {
                await this.$refs.form.validate();
                loading = this.$loading();

                const resourceids = this.endEnum.map(({value}) => {
                    const ref = this.$refs["tree"+value]?.[0];
                    const arr = [
                        ref?.getHalfCheckedKeys() || [],
                        ref?.getCheckedKeys() || []
                    ];
                    return arr;
                }).flat(Infinity).join()

                const res = await this.$axios({
                    url: "/api/role/saveRole",
                    method: "post",
                    data: {
                        ...this.PB,
                        resourceids
                    }
                })

				if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    })

                    this.$router.back();
					loading.close();

				} else if (res.code !== 1003)
					throw res

            } catch (reason) {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                if (reason !== false) {
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "warning"
                    });
                    console.warn(reason)                 
                }
                loading.close();
            }
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        }
    },

    created () {
        const sys_id = parseInt(this.$route.query.sys_id);
        if (sys_id) this.PB.sysId = sys_id;
        else if (!this.PB.sysId) {
            const firstSys = this.systemEnum[0];
            this.PB.sysId = firstSys?.id || "";
        }

        // 有 id 的情况，在 @/mixin/forEdit 已经处理了，
        // 这个没有 id 也发送请求的目的是获取 树结构 的信息
        const id = parseInt(this.$route.params.id);
        if (!id) {
            this.fetchData(true);
        }
    },
}