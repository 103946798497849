import Tree from "../../../utils/Tree"
import forList from "@/mixin/forList";
import OrganizationEdit from "./OrganizationEdit.vue";
import { treeProps } from "./config";
const testTree = new Tree({
    parentKey: "pid"
});

export default {
    name: 'Organization',
    mixins: [forList],
    components: {OrganizationEdit},

    data () {
        return {
            treeIns: testTree,
            treeProps,
            defaultExpandedKeys: [],
            filterWords: "",
            SP_: {
                searchName: "",
                searchParent : 0
            },
            onlyTableFlag: false, // 获取列表后只更新表格，不重置树
            allCategory: [],      // 全部分类
            editingItem: null,
        }
    },

    computed: {
        tree () {
            return this.treeIns.children
        }
    },

    watch: {
        "SP_.searchParent": function (id) {
            if (id) {
                this.$refs.tree.setCurrentKey(id)
            } else {
                this.$refs.tree.setCurrentKey(null)
            }
        }
    },

    methods: {

        watchSystemChange () {
            this.SP.searchName = "";
            this.SP.searchParent = 0;
            this.handlePageNoChange(this.SP.pageNum)
        },

		async fetchData(onlyTable=false) {
			const loading = this.$loading();

			try {
				const res = await Promise.allSettled([this.fetchTable(), onlyTable?undefined:this.fetchTree()]);

				if (res.some(({status}) => status != "fulfilled"))
                    throw res;

                loading.close();

			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();
			}
		},

		async fetchTable() {
            const res = await this.$axios({
                url: "/api/org/orglist",
                method: "post",
                data: {
                    ...this.extractParams(),
                    sysId: this.SP.search_sys || undefined
                }
            });

            if (res.code === 2000) {
                this.total = res.data.count;
                const list = res.data.orglist;
                this.tableData = list.map(it => {
                    it.sysOrganizations = it.sysOrganizations?.filter(
                        s => !!s.sysStateName).map(
                        s => {
                            const textArr = s.sysStateName.split("-");
                            s.systxt = textArr[0];
                            s.vietxt = textArr[1];
                            s.statxt = textArr[2];
                            return s;
                        }
                    );
                    return it;
                })||[];

            } else if (res.code !== 1003)
                throw res
		},

		async fetchTree() {
            const res = await this.$axios({
                url: "/api/org/queryOrgAll",
                method: "post",
                data: {
                    sysId: this.SP.search_sys || undefined,
                    isSysInfo: 1
                }
            });

            if (res.code === 2000) {
                const list = res.data.allOrganization;
                list.forEach(it => {
                    it.sysOrganizations = it.sysOrganizations?.filter(
                        s => !!s.sysStateName).map(
                        s => {
                            const textArr = s.sysStateName.split("-");
                            s.systxt = textArr[0];
                            s.vietxt = textArr[1];
                            s.statxt = textArr[2];
                            return s;
                        }
                    );
                })||[];

                // 生成树
                this.defaultExpandedKeys = list.filter(({level}) => level == 3).map(({id})=>id);
                this.treeIns = new Tree({ list, parentKey: "pid" })

            } else if (res.code !== 1003)
                throw res
		},

        // 点击搜索
        handleSearch (onlyTable=false) {
            this.updateSP_toSP();
            this.savePageState();
            this.fetchData(onlyTable);
        },

        // 在搜索栏的输入框按回车键
        handleInputEnter (e) {
            e.target.blur();
            this.handleSearch(true);
        },

        // 点击树的节点
        handleNodeClick(data) {
            this.SP_.searchParent = data.id;
            this.handleSearch(true)
        },

        // 过滤树节点的函数
        handleFilterButton () {
            this.$refs.tree.filter(this.filterWords);
            this.SP_.searchParent = "";
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name === "系统" || data.name.indexOf(value) !== -1;
        },

		// 点击 "新增菜单"
		handleAddBtn() {
            if (!this.SP_.searchParent) return this.$message({
                message: "请选中一个父级再添加",
                type: "warning"
            })
            const parent = this.treeIns.find(this.SP_.searchParent)
			this.editingItem = {
                name: "",
                parent,
                sysOrganizations: parent.sysOrganizations
            }
		},

		// 点击表格行操作按钮: 编辑 
		handleEdit(data) {
			this.editingItem = {
                name: data.name,
                id: data.id,
                sysOrganizations: data.sysOrganizations,
                parent: this.treeIns.find(data.pid)
            }
		},
        // 保存成功时
        refreshPage () {
            this.SP_.searchName   = "";
            this.SP_.searchParent = "";
            this.filterWords = "";
            this.handleSearch()
        }, 

		// 点击表格行操作按钮: 删除
		async handleDelete(data) {
			let loadingRef;
			try {
				await this.$confirm("确定要删除吗", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/org/delOrg",
					method: "post",
					data: {
						id: parseInt(data.id)
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.refreshPage();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "删除失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		}

    },

    created () {
		this.auth.save = this.$hasAuthFor("api/org/addOrg");
		this.auth.delete = this.$hasAuthFor("api/org/delOrg");
    }
}