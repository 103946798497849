<!-- 配置角色 -->
<template>
    <el-dialog class="menu-edit"
        :visible="viewIsReady"
        @close="handleClose"
        title="配置角色"
        width="500px">

        <el-form class="private" @submit.prevent="handleSave">
            <el-form-item>
                <el-checkbox-group v-model="roles" @change="handleChange">
                    <el-checkbox
                        v-for="it in roleEnum"
                        :key="it.id"
                        :label="it.id">
                        {{ it.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">
                取消</el-button>

            <el-button @click="handleSave"
                type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'SetRoles',
    props: ["item"],

    data () {
        return {
            roleEnum: [],
            viewIsReady: false,
            roles: [],
            rules: {
                roles: {
                    required: true,
                    message: "请至少选择一个角色",
                    trigger: "blur"
                }
            }
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                const roles = item.roleIds ? item.roleIds.split(",").map(
                    it => parseInt(it)
                ) : [];
                this.roles = Array.from(new Set(roles));
                this.fetchConfig();
                this.viewIsReady = true;
            }
        }
    },

    methods: {
		// 获取角色配置参数
		async fetchConfig () {
			try {
				const res = await this.$axios({
					url: "/api/role/queryRoleAll",
                    data: {
                        sysId: this.item.sys_id
                    },
					method: "post"
				})
				if (res.code === 2000) {
					this.roleEnum = res.data.roleList;
                    this.roles = this.roles.filter(
                        id => res.data.roleList.some(r => 
                        r.id === id)
                    )

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取岗位配置参数出错", reason);
			}
		},

        async handleSave () {
            if (!this.roles.length) return this.$message({
                message: "请至少选择一个角色",
                type: "warning"
            })

            const loadingRef = this.$loading({
                target: ".white-list-edit .el-dialog"
            });
            try {
                const res = await this.$axios({
                    url: "/api/resource/bindRole",
                    method: "post",
                    data: {
                        resourceid: this.item.id,
                        roleIds   : this.roles.join()
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.viewIsReady = false;
            this.roles = [];
            this.roleEnum = [];
            this.$emit("update:item", null);
        },

        handleChange (val) {
            console.log(val, val.map(it => typeof it))
        }
    }
}
</script>

<style lang='scss' scoped></style>