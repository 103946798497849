import forList from "@/mixin/forList";
import MenuEdit from "./MenuEdit.vue";
import SetRoles from "./SetRoles.vue";
import { levelEnum, initialPB } from "./config";


export default {
	name: "MenuList",
	mixins: [forList],
	components: { MenuEdit, SetRoles },

	data() {
		return {
			levelEnum,         // 等级的选项
			resourceSourceTypeEnum: [],
			SP_: {
				search_menu: "",  // 菜单名
				search_sourcetype : "",  // 应用端
				search_type: "",  // 类型，见类型配置
				search_path: "",  // 地址
			},
			editingItem: null,
			setingItem : null,
		};
	},

	methods: {
		async fetchData() {
			console.log("fetching...");
			const loading = this.$loading();

			try {
				const res = await this.$oxios({
					url: "/api/resource/queryResourceAll",
					method: "post",
					data: {
						...this.extractParams(),
						search_type: this.SP.search_type || undefined
					}
				});

				if (res.code === 2000) {
					this.total = res.data.count;
					this.levelEnum = res.data.resourceTypeEnum;
					this.resourceSourceTypeEnum = res.data.resourceSourceTypeEnum;
					const list = res.data.resourcelist;
					this.tableData = list;

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();
			}
		},

		// 点击 "新增菜单"
		handleAddBtn() {
			this.editingItem = initialPB();
		},

		// 点击表格行操作按钮: 编辑 
		handleEdit(data) {
			this.editingItem = data;
		},

		// 点击表格行操作按钮: 编辑 
		handleSet(data) {
			this.setingItem = data;
		},

		// 点击表格行操作按钮: 删除
		async handleDelete(data) {
			let loadingRef;
			try {
				await this.$confirm("确定要删除吗", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/resource/deleteResource",
					method: "post",
					data: {
						resourceid: parseInt(data.id)
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "删除失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		}
	},

	created () {
		this.auth.save = this.$hasAuthFor("api/resource/saveResource");
		this.auth.delete = this.$hasAuthFor("api/resource/deleteResource");
		this.auth.setRole = this.$hasAuthFor("api/resource/bindRole");
	}
};