export const systemList = [
    {
        id: 1,
        name: "系统1"
    },
    {
        id: 2,
        name: "system2"
    },
    {
        id: 3,
        name: "system3"
    }
];

export const treeProps = {
    children: 'children',
    label: 'name'
};

export const tree = [{
    name: "全部",
    id: 0,
    children: []
}];