import forList from "@/mixin/forList";
import ConfigEdit from "./ConfigEdit.vue";
import {initialPB} from "./config";

export default {
	name: "SystemConfig",
	mixins: [forList],
	components: {ConfigEdit},

	data() {
		return {
			SP_: {
				searchName: "", // 功能描述
			},
			sysTypeEnum: [],
            editingItem: null,
		};
	},

	methods: {
		async fetchData() {
			console.log("fetching...");
			const loading = this.$loading();

			try {
				const res = await this.$axios({
					url: "/api/system/querySysList",
					method: "post",
					data:this.extractParams()
				});

				if (res.code === 2000) {
					this.total = res.data.count;
					const list = res.data.sysList;
					// 解析出的 positionIds 将在编辑时使用
					// list.forEach(it => {
					// 	it.positionIds = it.position_ids.split(",").map(
					// 		it => parseInt(it)
					// 	).filter(Boolean)
					// });
					this.tableData = list;
					this.sysTypeEnum = res.data.SysTypeEnum || [];

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();	
			}
		},

		// 点击 "新增菜单"
		handleAddBtn() {
			this.editingItem = initialPB();
		},

		// 点击表格行操作按钮: 编辑 
		handleEdit(data) {
			this.editingItem = data;
		},
        // 点击表格行操作按钮: 皮肤设置
        handleSkin(data) {
            this.$router.push({
                name: `SystemSkin`,
                params: { searchValue: data.sys_code },
            });
        },


		// 点击表格行操作按钮: 删除
		async handleDelete(data) {
			let loadingRef;
			try {
				await this.$confirm("确定要删除吗", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/system/delSys",
					method: "post",
					data: {
						id: parseInt(data.id)
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "删除失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		}
	},

	created () {
		this.auth.save = this.$hasAuthFor("api/system/saveSystem");
		this.auth.delete = this.$hasAuthFor("api/system/delSys");
        this.auth.skin = this.$hasAuthFor("api/configuration/saveSysIcon");

	}
};