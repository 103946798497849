
export default {
    name: 'UploadFile',
    props: {
        showDelete: {
            type: Boolean,
            default: true
        },
        accept: {
            type: String,
            default: "image/*"
        },
        limit: {
            type: Number,
            default: 1
        },
        maxSize: {
            type: Number,
            default: 200 // kB
        },
        value: {
            type: Array,
            default: () => []
        },
        body: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            action: this.$baseURL + "/api/common/fileSingleUpLoad",
            uploadHeaders: { // 图片上传的请求头
                token: this.$store.state.token
            },
            fileList: [],
            posting: false
        }
    },

    watch: {
        value (files) {
            console.log('value', this.value);
            this.fileList = files;
        }
    },

    methods: {
        handleExceed() {
            this.$message({
                message: `最多只能上传${this.limit}个`,
                type: "error",
            });
        },

        beforeFileUpload(file) {
            const isSizeOk = file.size / 1024 < this.maxSize;
            if (!isSizeOk) {
                this.$message.error('上传的图标大小不能超过 200KB!');
                return false
            }

            this.fileList.push(file);
            return true;
        },

        handleProgress(e, file) {
            this.posting = true;
            const index = this.fileList.indexOf(file);
            this.$set(this.fileList, index, file)
        },

        // 上传文件成功
        handleUploadSuccess(res, file) {
            this.posting = false;
            const index = this.fileList.indexOf(file);
            if (res.code === 2000) {
                const { oldName, filePath } = res.data.files;
                file.url = this.$baseURL + "/" + filePath;
                file.path = filePath;
                this.$set(this.fileList, index, {
                    name: oldName,
                    url: this.$baseURL + "/" + filePath,
                    path: filePath
                })
                
                this.$emit("input", this.fileList);
            } else {
                this.fileList.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },

        // 删除文件
        handleIconDelete(index) {
            this.fileList.splice(index, 1);
            this.$emit("input", this.fileList);
        },

    }
}