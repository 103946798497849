<template>
<div class="page list-page">
<div class="page--list">
    <el-form class="private scroll-wrapper pd4 set--bottom-foot-padding"
        :model="PB"
        :rules="rules"
        label-width="150px"
        label-position="right"
        label-suffix=":"
        ref="form"
    >
        <div class="wrapper">
            <el-form-item label="系统" prop="systype">
                 <el-select
                    disabled
                    v-model="PB.systype"
                    class="width3"
                    clearable>

                    <el-option
                        v-for="end in appConfigSysTypeEnum"
                        :key="end.value"
                        :label="end.name"
                        :value="end.value"
                    ></el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="二维码图片" prop="files" >
                 <UploadFile v-model="PB.files"
                    :body="{isZip: 1}"></UploadFile>
            </el-form-item>
            <!-- {{fileList}} -->
            <el-form-item label="文件附件" prop="path" >
                <el-input readonly  class="width3" v-model="PB.path">
                     <el-upload
                        v-if="!PB.path && !pathing"
                        slot="append"
                        :limit='1'
                        class="upload-demo"
                        :headers='uploadHeaders'
                        ref="upload"
                        :action="$baseURL+'/api/configuration/uploadFile'"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :on-success="handleUploadSuccess"
                        :on-change="handleFileChange"
                        :on-exceed="handleExceed"
                        :on-progress="handleProgress"
                        :auto-upload="true">
                        <el-button slot="trigger" size="small" type="primary">选择文件</el-button>
                    </el-upload>
                    <el-button v-if="pathing" @click="delPath" slot="append" type="primary">上传中...</el-button>
                    <el-button v-if="PB.path && !pathing" @click="delPath" slot="append" type="primary">删除</el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input v-model="PB.remark"
                    type="textarea" rows="5"
                    maxlength="100"
                    class="width3"></el-input>
            </el-form-item>
        </div>
        <div class="foot-bar">
            <el-button :disabled="submitDisabled" @click="handleNavigationBack">取消</el-button>
            <el-button type="primary" :disabled="submitDisabled" @click="handleSubmitButton">保存</el-button>
        </div>
    </el-form>
</div>
</div>
</template>

<script>

import UploadFile from '../../../components/UploadFile/UploadFile.vue'


export default {
    components: {UploadFile},
    name: 'AppConfigEdit',
    // mixins: [forEdit],
    data () {
        const _this = this;
        async function checkSamePhone (rules, val, cb) {
            // console.log(_this);
            const fine = await _this.handlePhoneChange(val);
            if (fine) cb();
            else cb("手机号码重复")
        }
        async function checkSameAccount (rules, val, cb) {
            // console.log(_this);
            const fine = await _this.handleAccontChange(val);
            if (fine) cb();
            else cb("账号重复")
        }
        function pwdValidator (r, value, cb) {
            if (!_this.PB.id) {
                if (!(/^[0-9a-zA-z_]{6,20}$/.test(value)))
                    cb("只能是字母或数字，长度6-20位")
                else cb()
            }
            else {
                if (value.length > 0 && !(/^[0-9a-zA-z_]{6,20}$/.test(value)))
                    cb("只能是字母或数字，长度6-20位")
                else cb()
            }
        }

        return {
            id:'',
            appConfigSysTypeEnum:[],

            codePic: [],
             PB: {
                systype:'',
                remark:'',
                files:[],
                path:'',

                sendEmailName:'',// 发件人邮箱
                sendEmailAccount:'',// 发件人邮箱账号
                sendEmailPsd:'',// 发件人邮箱密码
                sendEmailTheme:'',// 主题
                files1: [],
                content:'', //邮件内容
            },
            pathing:false,



            editorOptions: {// 编辑器选项配置（可根据需求进行自定义）
                placeholder: '请输入内容', // 设置占位符
                // modules: {
                //     toolbar: [
                //     ['bold', 'italic', 'underline'], // 设置工具栏按钮
                //     [{ header: [1, 2, false] }], // 设置标题样式
                //     ['link', 'image'] // 添加链接和图片插入功能
                //     ]
                // },
                maxLength: 10 // 设置最大长度为1000个字符（包括HTML标签）
            },
            maxTextLength:10,
          
            submitDisabled:false,
            uploadHeaders: { // 图片上传的请求头
                token: this.$store.state.token
            },
            fileList: [],
            accept:'',


            // genderEnum, // 性别选项
            // empMap: {}, // 员工信息
           
            rules: {
                systype   : { required: true, message: "请选择系统" },
                files   : { required: true, message: "请上传二维码图片" },

                // sendEmailName: [
                //     { required: true, message: "请输入发件人邮箱" },
                //     { validator (r, value, cb) {
                //         if (!(/^[A-Za-z0-9\u4e00-\u9fa5._]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)))
                //             cb("请输入正确的邮箱格式")
                //         else cb()
                //     }},
                // ],
                // sendEmailAccount   : { required: true, message: "请输入发件人邮箱账号" },
                // sendEmailPsd   : { required: true, message: "请输入发件人邮箱密码" },
                // sendEmailTheme  : { required: true, message: "请输入主题" },
                // files1: {required: true, message: "请上传收件邮箱"},
                // content: {required: true, message: "请输入邮件内容"},







                // email: [
                //     { required: true, message: "请输入公司邮箱" },
                //     { validator (r, value, cb) {
                //         if (!(/^[A-Za-z0-9\u4e00-\u9fa5._]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)))
                //             cb("请输入正确的邮箱格式")
                //         else cb()
                //     }},
                // ],


                // phone  : [
                //     { required: true, message: "请输入电话" },
                //     { validator (r, value, cb) {
                //         if (!(/^\+?\d{5,15}$/.test(value)))
                //             cb("请输入正确的手机号码格式");
                //         else cb();
                //     }},
                //     { validator: checkSamePhone, trigger: "blur" }
                // ],

                // account: [
                //     { required: true, message: "请输入账号" },
                //     { validator (r, value, cb) {
                //         if (!(/^[0-9a-zA-z_]{2,30}$/.test(value))) 
                //             cb("账号只能是数字或字母, 最少2位")
                //         else cb()
                //     }},
                //     { validator: checkSameAccount, trigger: "blur" }
                // ],

                // pwd: { validator: pwdValidator },
                
                // empSysList: {required: true, message: "至少需要添加一个系统"}
            },
            systemList: [],     // 用户界面的系统列表数据，包含已保存和未保存的
            activeSystem: null, // 当前激活的系统tab
        }
    },

    created () {
		// this.auth.save = this.$hasAuthFor("api/category/saveCategory") || true;
        // if (!this.$route.params.id) this.handleAddSystem();
        console.log(this.$route.params.id);
        this.id = this.$route.params.id
        this.fetchData()
    },

    methods: {
        delPath(){
            this.PB.path = ''
            this.fileList = []
        },
        handleTextChange(event) {
            const text = event.text;
            
            // if (text.length > this.maxTextLength) {
            // const truncatedText = text.slice(0, this.maxTextLength);
            
            // this.content = truncatedText;
            
            // const delta = new Quill.import('delta');
            
            // delta.retain(truncatedText.length)
            //     .delete(text.length - truncatedText.length);
                
            // this.$refs.quillEditor.quill.updateContents(delta);
            // }
        },

        // 点击取消
        handleNavigationBack () {
            this.$router.back();
        },
        // handleSubmitButton(){
        //     console.log(this.PB.files);
        //          if ([...this.PB.files].some(({percentage,status}) => (percentage < 100 || status =='uploading')))
        //             return this.$message({
        //                 message: "请等待附件上传完毕",
        //                 type: "error"
        //             });
        //         // return  this.$refs.form1.validate()
        //             // console.log(123);
        // },

        async validateUpdate () {
             if ([...this.PB.files,...this.fileList].some(({percentage,status}) => (percentage < 100 || status =='uploading'))){
                 this.$message({
                     message: "请等待附件上传完毕",
                     type: "error"
                 });
                 return Promise.reject(false)
             }else{
                return true;
             }
            // if (this.systemList.some(sys => {
            //     if (!sys.locateId) {
            //         this.activeSystem = sys;
            //         const fine = this.$refs.departmentconfig.handleSaveButton();
            //         if (!fine) return Promise.reject(false);
            //         return true;
            //     }
            //     return false;
            // })) {
            //     return Promise.reject(false);
            // }
            // else return;
        },



         async handleSubmitButton () {
            const loadingRef = this.$loading();
            try {
                await this.$refs.form.validate();
                // await this.validateSystems();
                await this.validateUpdate();

                // const {name,phone,account,pwd,enName,sex,age,inDate,email,weixinCode,remark1,empSysList} = this.PB;
                // const data = {
                //     name,phone,account,enName,sex,age,inDate,email,weixinCode,remark1,
                //     empSysList  : JSON.stringify(empSysList.map(it => ({
                //         system  : it.system,
                //         postId  : it.postId,
                //         locate  : it.locate,
                //         locateId: it.locateId,
                //         manage  : it.manage.map(m => m.code || m).join(),
                //         manageId: it.manage.map(m => m.id || "").join()
                //     }))),
                //     id: this.PB.id
                // }
                // if (pwd) {
                //     console.log("before encrypto", pwd);

                //     const CryptoJS = window.CryptoJS;
                //     // var key       = CryptoJS.enc.Utf8.parse("CENTER001");
                //     var key       = CryptoJS.enc.Utf8.parse("YIMING5AUS900001");
                //     var srcs      = CryptoJS.enc.Utf8.parse(pwd);
                //     var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
                //     data.pwd      = encrypted.toString();

                //     // data.pwd = this.$CryptoJS.AES.encrypt(pwd, "center001").toString()
                // }

                // console.log("提交的数据", data);
                const res = await this.$axios({
                    url: "/api/configuration/saveAppConfig",
                    method: "post",
                    data:{
                        id:this.id,
                        image:this.PB.files.map(it=>it.path).join(),
                        path:this.PB.path,
                        remark:this.PB.remark,
                        systype:this.PB.systype
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.handleNavigationBack();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                // console.log(reason)
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
        // 点击发送
        async handleSubmitButton1 () {


            const loadingRef = this.$loading();

            try {
                await this.$refs.form.validate();
                await this.validateSystems();
                const {name,phone,account,pwd,enName,sex,age,inDate,email,weixinCode,remark1,empSysList} = this.PB;
                const data = {
                    name,phone,account,enName,sex,age,inDate,email,weixinCode,remark1,
                    empSysList  : JSON.stringify(empSysList.map(it => ({
                        system  : it.system,
                        postId  : it.postId,
                        locate  : it.locate,
                        locateId: it.locateId,
                        manage  : it.manage.map(m => m.code || m).join(),
                        manageId: it.manage.map(m => m.id || "").join()
                    }))),
                    id: this.PB.id
                }
                if (pwd) {
                    console.log("before encrypto", pwd);

                    const CryptoJS = window.CryptoJS;
                    // var key       = CryptoJS.enc.Utf8.parse("CENTER001");
                    var key       = CryptoJS.enc.Utf8.parse("YIMING5AUS900001");
                    var srcs      = CryptoJS.enc.Utf8.parse(pwd);
                    var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
                    data.pwd      = encrypted.toString();

                    // data.pwd = this.$CryptoJS.AES.encrypt(pwd, "center001").toString()
                }

                // console.log("提交的数据", data);
                const res = await this.$axios({
                    url: "/api/employee/saveEmp",
                    method: "post",
                    data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.handleNavigationBack();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                // console.log(reason)
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
        submitUpload() {
            if (this.fileList.length == 0) {
                this.$message({
                    message: "请选择上传文件",
                    type: "warning"
                });
            } else {
                // this.uploading = this.$loading();

                // this.uploading = this.$loading({
                //     target: ".circulation-money .el-dialog"
                // });
                console.log(1123);
                this.$refs.upload.submit();
            }
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
            this.fileList = fileList
            this.PB.files1 = []
        },
        handlePreview(file) {
            console.log(file);
        },
        handleFileChange(files) {
            this.fileList = [files]
        },
         handleProgress(e, file) {
            this.pathing = true;
        },
        handleUploadSuccess(res, file) {
            console.log(res);
            this.PB.path = res.data.fileUploadInfoVO.filePath
            this.pathing = false;



            // this.uploading.close();
            // if (res.code == 2001) {
            //     this.$message({
            //         message: res.msg || "文件有误",
            //         type: "warning"
            //     });
            //     setTimeout(() => {
            //         this.$refs.upload.clearFiles();
            //         this.fileList = []
            //     }, 20);
            // }
            // if (res.code == 2000) {
            //     this.$message({
            //         message: '上传成功，核算结果已自动导出',
            //         type: "success"
            //     });
            //     setTimeout(() => {
            //         this.$refs.upload.clearFiles();
            //         this.fileList = []
            //         this.showCheckAchievementDia = false
            //     }, 500);
            //     setTimeout(() => {
            //         if (res.data.returnFile) {
            //             window.open(this.$baseURL + '/' + res.data.returnFile, "download");
            //         }
            //     }, 2000);
            // }
        },
        handleExceed() {
            this.$message({
                message: `最多只能上传1个文件`,
                type: "warn",
            });
        },
        async downLoadfile() {
            let loadingRef,
                url = '/api/order/downloadCheckTemplat'
            loadingRef = this.$loading();

            const res = await this.$axios({
                method: "post", url, data: {}
            })
            console.log(res);
            if (res.code === 2000) {
                // this.$message({
                //     message: "下载成功",
                //     type: "success"
                // });
                if (res.data.path) {
                    window.open(this.$baseURL + '/' + res.data.path, "download");
                }
                loadingRef && loadingRef.close();
            } else if (res.code !== 1003)
                throw res;
        },




        // 获取页面数据
        async fetchData() {
			const loading = this.$loading();

			try {
				const res = await this.$axios({
					url: "/api/configuration/queryAppConfigInfo",
					method: "post",
					data: {id: this.id}
				});

				if (res.code === 2000) {
                    this.appConfigSysTypeEnum = res.data.appConfigSysTypeEnum||[]
                    this.PB.systype = res.data.appConfig.systype
                    this.PB.remark = res.data.appConfig.remark
                    this.PB.files = res.data.appConfig.image ? [{
                        name: res.data.appConfig.image.slice(res.data.appConfig.image.lastIndexOf("/")+1),
                        url: this.$baseURL + "/" + res.data.appConfig.image,
                        path: res.data.appConfig.image
                    }] : []; // todo 
                     this.PB.path = res.data.appConfig.path
                    

                        // icon      : files.map(it=>it.path).join(),





                    // const {empMap} = res.data;
                    // this.empMap = empMap;

                    // // 把远程的数据回显出来
                    // this.PB.name       = empMap.name
                    // this.PB.phone      = empMap.phone
                    // this.PB.account    = empMap.account
                    // this.PB.enName     = empMap.enName  || ""
                    // this.PB.sex        = parseInt(empMap.sex) || ""
                    // this.PB.age        = empMap.age     || ""
                    // this.PB.inDate     = empMap.inDate  || ""
                    // this.PB.email      = empMap.email   || ""
                    // this.PB.weixinCode = empMap.weixinCode || ""
                    // this.PB.remark1    = empMap.remark1 || ""
                    // this.PB.empSysList = empMap.empSysList.map(item => ({
                    //     id               : item.id,
                    //     system           : item.system,
                    //     postId           : item.post_id   || "",
                    //     locate           : item.locate    || "",
                    //     locateId         : (item.locate && item.locate_id) ? item.locate_id : "",
                    //     postLevel        : 0,
                    //     locateLevel      : 0,
                    //     departmentMixName: item.locatePreName  || "",
                    //     // manage           : item.manage ? item.manage.split(",") : [],
                    //     manage           : item.manageList || [],
                    //     systemName       : item.sysName,
                    //     saveState        : 1,
                    //     active           : false,
                    // }));
                    // this.systemList.push(...this.PB.empSysList)

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();	
			}
        },
        // 手机号验重
        async handlePhoneChange (val) {
            try {
                const res = await this.$axios({
                    url: "/api/employee/querySamePhone",
                    method: "post",
                    data: {
                        phone: val,
                        id   : this.PB.id
                    }
                })

                if (res.code === 2000) {
                    if (res.data.count) {
                        return false
                    } else return true;

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                console.warn(reason);
                return false
            }
        },
        // 账号验重
        async handleAccontChange (val) {
            try {
                const res = await this.$axios({
                    url: "/api/employee/querySameAccount",
                    method: "post",
                    data: {
                        account : val,
                        id      : this.PB.id
                    }
                })

                if (res.code === 2000) {
                    if (res.data.count) {
                        return false
                    } else return true;

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                console.warn(reason);
                return false
            }
        },

        // 添加一个系统
        handleAddSystem () {
            // const item = createSystemData();
            // this.systemList.push(item);
            // this.handleTabClick(item);
        },

        // 移除一个系统
        async handleSystemRemove (item) {
            if (item.id) return this.jumpForRemove(item);

            try {
                if (item.saveState !== 0) {
                    await this.$confirm("确实删除该系统吗?", "提示")
                }

                const {systemList} = this;
                let index = systemList.indexOf(item);
                this.systemList.splice(index, 1);
    
                index = this.findIndexInRegular(item);
                if (index >= 0) this.PB.empSysList.splice(index, 1);

                if (item == this.activeSystem) this.activeSystem = null;
                this.$message({
                    message: "移除成功",
                    type: "success"
                })
                
            } catch (reason) {
                if (reason != "cancel") {
                    console.warn("移除系统的事件处理出错", reason)
                }
            }
        },
        async jumpForRemove (item) {
            try {
                await this.$confirm(
                    `您是要取消${item.systemName}系统的使用权限么?请去业务系统进行离职操作即可`,
                    '取消系统使用权限', {
                        confirmButtonText: '去离职',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                )

                if (item.system === "CEN") return this.handleNavigationBack();
                const sys = this.systemEnum.find(s => s.sysCode === item.system);
                if (!sys.outUrl) return console.log("改配置没有找到匹配的跳转地址", item);

                const loading = this.$loading();
                const res = await this.openSystemURL({
                    url: sys.outUrl,
                    // url: "http://localhost:8030/#/core/staff",
                    system: item.system,
                    params: {
                        empCode: this.empMap.usercode
                    }
                })
                loading.close();
                // if (res !== 2000) throw res;

            } catch (reason) {
                if (reason != "cancel") {
                    console.warn("移除系统的事件处理出错", reason)
                }
            }
        },

        // 查找某个 system 数据在正式数据中的索引 （临时数据和正式数据没
        // 有引用同一个对象，适用JSON.PARSE,JASON.STRINGFY 进行了隔离）
        findIndexInRegular (item) {
            const {empSysList} = this.PB;
            return empSysList.findIndex(it => {
                if (it.id) return it.id === item.id;
                else if (it.nanoid) return it.nanoid === item.nanoid
                else return false
            });
        },

        // 选择系统标签（tab）
        handleTabClick (item) {
            if (item === this.activeSystem) return;
            if (this.activeSystem)
                this.activeSystem.active = false;
            item.active = true;
            this.activeSystem = item;
        },

        // 保存系统（小保存）
        handleSystemSave (manual = false) {
            const {activeSystem: item} = this;

            if (item.saveState === 0) {
                item.saveState = 1;
                this.PB.empSysList.push(JSON.parse(JSON.stringify(item)));

            } else {
                item.saveState = 1;
                const index = this.findIndexInRegular(item);
                this.PB.empSysList.splice(index, 1, JSON.parse(JSON.stringify(item)))
            }

            if (manual) this.$message({
                message: "保存成功",
                type: "success"
            })
        },

        

        async validateSystems () {
            if (this.systemList.some(sys => {
                if (!sys.locateId) {
                    this.activeSystem = sys;
                    const fine = this.$refs.departmentconfig.handleSaveButton();
                    if (!fine) return Promise.reject(false);
                    return true;
                }
                return false;
            })) {
                return Promise.reject(false);
            }
            else return;
        },

        
        handleSuggestInputFocus (e) {
            setTimeout(() => {
                e.target.removeAttribute("readonly")
            }, 1);
        },
        handleSuggestInputBlur (e) {
            e.target.setAttribute("readonly", "readonly")
        },
    }
}
</script>


<style lang='scss' scoped>
    // @import "./staffEdit.scss";
    .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1000px;
}

.el-form-item {
    // width: 48%;
    width: 90%;
    
    &.full-width {
        width: 100%;
    }
}

.department-config {
    margin-left: 120px;
    max-width: 880px;
}

.handler-button {
    height: 32px;
    line-height: 30px;
    margin-left: 18px;
    padding: 0 12px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #2878FF;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    // overflow-x: hidden;
    // overflow-y: visible;
    text-overflow: ellipsis;
    color: #2878FF;

    &:hover {
        background-color: rgba($color: #2878FF, $alpha: .05);
    }

    .el-button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        padding-left: 2px;
        background-color: rgba($color: #2878FF, $alpha: .19);
        border-radius: 0 0 0 16px;
        height: 16px;
    }

    .triangle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        width: 0;
        height: 0;
        margin: auto;
        border: 10px solid transparent;
        border-top-width: 0;
        border-bottom-width: 16px;
        border-bottom-color: #ECF0F4;
    }
}

.foot-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0; left: 0; right: 0;
    z-index: 9;
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: white;
    box-shadow: 0px -2px 4px 0px rgba(200, 201, 204, 0.35);
}

/deep/ {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 100%;
    }
}

/deep/ .ql-container{
    height: 200px;
}

/deep/ .el-input-group__append{
    color: #fff;
    top: 2px;
    border: none;
}
/deep/ .el-input-group__append .el-button{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
/deep/ .el-input-group__append .el-upload-list{
    display: none;
}
</style>