<!-- 添加、编辑字典项 -->
<template>
    <el-dialog class="menu-edit"
        :visible="viewIsReady"
        @close="handleClose"
        :title="titleText"
        width="500px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="100px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="应用端" prop="sourceType">
                <el-select v-model="PB.sourceType"
                    class="width3">
                    <el-option
                        v-for="e in endEnum"
                        :key="e.value"
                        :label="e.name"
                        :value="e.value"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="所属系统" prop="sysId">
                <el-select v-model="PB.sysId"
                    @change="handleSysChange"
                    :disabled="isUpdate"
                    class="width3">
                    <el-option
                        v-for="level in systemEnum"
                        :key="level.id"
                        :label="level.name"
                        :value="level.id"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="类型" prop="type">
                <el-select v-model="PB.type"
                    @change="handleLevelChange"
                    class="width3">
                    <el-option
                        v-for="l in levelEnum"
                        :key="l.value"
                        :label="l.name"
                        :value="l.value"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="父类名称"
                prop="parentId"
                v-if="PB.type > 1 && PB.sysId"
            >
                <el-select v-model.number="PB.parentId"
                    filterable
                    class="width3">
                    <el-option
                        v-for="p in parentEnum"
                        :key="p.id"
                        :label="p.menu_name"
                        :value="p.id"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="菜单名" prop="menuName">
                <el-input v-model.trim="PB.menuName"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="后台url地址" prop="path">
                <el-input v-model.trim="PB.path"
                    maxlength="100"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="前台url地址" prop="webPath">
                <el-input v-model.trim="PB.webPath"
                    maxlength="100"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="Icon图标" prop="files">
                <UploadFile v-model="PB.files"
                    :body="{isZip: 1}"></UploadFile>
            </el-form-item>

            <el-form-item label="选中Icon图标" prop="files1">
                <UploadFile v-model="PB.files1"
                    :body="{isZip: 1}"></UploadFile>
            </el-form-item>

            <el-form-item label="排序" prop="order" class="wrap">
                <el-input v-model.number="PB.order"
                    class="width3"></el-input>
                <span class="hint">数字越小越靠前</span>
            </el-form-item>

            <el-form-item label="是否隐藏菜单" prop="isHide" class="flexItem">
                <el-radio v-model="PB.isHide" label="0">否</el-radio>
                <el-radio v-model="PB.isHide" label="1">是</el-radio>
            </el-form-item>

            <el-form-item label="备注" prop="content">
                <el-input v-model="PB.content"
                    type="textarea" rows="5"
                    class="width3"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import UploadFile from '../../../components/UploadFile/UploadFile.vue'
export default {
    name: 'MenuEdit',
    components: {UploadFile},
    props: ["item", "levelEnum", "endEnum"],

    data () {
        return {
            viewIsReady: false,
            PB: {
                sysId: "",
                sourceType: 1,
                type: "",
                parentId: "",
                menuName: "",
                path: "",
                webPath: "",
                order: 0,
                content: "",
                files: [],
                files1: [],
                isHide:'0', // 是否隐藏菜单 1否 2是
            },
            // 验证规则
            rules: {
                sysId      : { required: true, message: "请选择系统",   trigger: "blur" },
                sourceType : { required: true, message: "请选择应用端", trigger: "blur" },
                type       : { required: true, message: "请选择类型",   trigger: "blur" },
                parentId   : { required: true, message: "请选择父类",   trigger: "blur" },
                menuName   : { required: true, message: "请输入名称",   trigger: "blur" },
                path       : { required: true, message: "请输入url" ,   trigger: "blur" }
            },  
            parentEnum: []
        }
    },

    computed: {
        isUpdate () {
            return !!this.item?.id
        },
        titleText () {
            return this.isUpdate ? '编辑菜单' : '新增菜单'
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                console.log(item);
                for (let key in item) {
                    const _key = key.toCamel();
                    if (item[key] != null) this.PB[_key] = item[key];
                    if(item.is_hide){
                        this.PB.isHide  = item.is_hide +''
                    }else{
                        this.PB.isHide  = '0'
                    }
                }
                this.PB.files = item.icon ? [{
                    name: item.icon.slice(item.icon.lastIndexOf("/")+1),
                    url: this.$baseURL + "/" + item.icon,
                    path: item.icon
                }] : []; // todo 
                this.PB.files1 = item.chosenIcon ? [{
                    name: item.chosenIcon.slice(item.chosenIcon.lastIndexOf("/")+1),
                    url: this.$baseURL + "/" + item.chosenIcon,
                    path: item.chosenIcon
                }] : []; // todo 
                if (this.PB.type > 1 && this.PB.sysId) this.fetchParentList();
                this.viewIsReady = true;
            }
        }
    },

    methods: {
        handleLevelChange (val) {
            console.log(val, this.PB.sysId);
            if (val > 1 && this.PB.sysId) {
                this.fetchParentList();
            }
        },
        handleSysChange (val) {
            if (this.PB.type > 1) {
                this.fetchParentList()
            }
        },
        async fetchParentList () {
            this.parentEnum = [];

            try {
                const res = await this.$axios({
                    url: "/api/resource/queryResourceByLevel",
                    method: "post",
                    data: {
                        level: this.PB.type,
                        sysId: this.PB.sysId,
                        resourceType:this.PB.sourceType,
                    }
                });

				if (res.code === 2000) {
					this.parentEnum = res.data.resourceList;

				} else if (res.code !== 1003)
					throw res
                
            } catch (reason) {
				console.warn("获取父类失败", reason);
            }

        },

        async handleSave () {
            const loadingRef = this.$loading({
                target: ".white-list-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const {sysId, sourceType, type, parentId, menuName, path, webPath, order, content, files, files1,isHide} = this.PB;
                const res = await this.$axios({
                    url: "/api/resource/saveResource",
                    method: "post",
                    data: {
                        sysId, sourceType, type, menuName, path, webPath, order, content,isHide,
                        icon      : files.map(it=>it.path).join(),
                        chosenIcon: files1.map(it=>it.path).join(),
                        id        : this.PB.id,
                        parentId  : parentId || 0
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.viewIsReady = false;
            this.PB = {
                sysId: "",
                sourceType: 1,
                type: "",
                parentId: "",
                menuName: "",
                path: "",
                webPath: "",
                order: 0,
                content: "",
                files: [],
                files1: [],
                isHide:'0',
            };
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .hint {
        color: orangered !important;
    }
    .flexItem{
        display: flex;
        align-items: center;

    }
    .flexItem /deep/ .el-form-item__content{
        margin-left: 0 !important;
    }
</style>