<!-- 添加、编辑字典项 -->
<template>
    <el-dialog class="organization-edit"
        :visible="viewIsReady"
        @close="handleClose"
        :title="titleText"
        width="700px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="100px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >

            <el-form-item label="父类">
                <el-input :value="parent.prenames"
                    disabled class="width3"></el-input>
            </el-form-item>

            <el-form-item label="名称" prop="name">
                <el-input v-model.trim="PB.name"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="适用系统" prop="sysOrganizations"
                class="wrap"
            >
                <system-checkbox
                    v-for="sys in PB.sysOrganizations"
                    :key="sys.sys_id"
                    :value="sys"
                    @input="handleSysCheck"
                ></system-checkbox>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import CheckboxGroup from "./CheckboxGroup.vue"
export default {
    name: 'OrganizationEdit',
    props: ["item"],
    components: {
        "system-checkbox": CheckboxGroup
    },

    data () {
        return {
            viewIsReady: false,
            parent: {},
            PB: {
                id: undefined,
                name: "",
                pid: "",
                sysOrganizations: []
            },
            rules: {
                name: { required: true, message: "请输入名称", trigger: "blur" },
                sysOrganizations: {
                    validator (r, list, cb) {
                        if (list.some(it => it.checked)) {
                            cb()
                        } else cb("至少需配置一个系统")
                    }
                }
            },
        }
    },

    computed: {
        isUpdate () {
            return !!this.item?.id
        },
        titleText () {
            return this.isUpdate ? '编辑组织架构' : '新增组织架构'
        }
    },

    watch: {
        item (item, old) {
            if (item) {
                this.PB.id = item.id;
                this.PB.name = item.name;
                this.PB.pid  = item.pid || item.parent.id;
                this.parent = item.parent;

                let parentSystem = item.parent.sysOrganizations;
                if (!(parentSystem instanceof Array)) {
                    parentSystem = item.sysOrganizations;
                }

                this.PB.sysOrganizations = parentSystem.map(it => {
                    const foundInEnum = this.systemEnum.find(s => s.id === it.sys_id);
                    if (foundInEnum) {
                        const foundInSub = item.sysOrganizations.find(
                            s => s.sys_id === foundInEnum.id);
                        return {
                            // ...it,
                            sys_id: it.sys_id,
                            name: foundInEnum.name,
                            status: !!foundInSub?.status,
                            view_state: !!foundInSub?.view_state,
                            checked: !!foundInSub
                        }
                    }
                    else return null;
                }).filter(it => it != null) || this.createNewSysOrganizations();
                this.viewIsReady = true;
            }
        },
    },

    methods: {
        createNewSysOrganizations () {
            return this.systemEnum.map(sys => ({
                sys_id: sys.id,
                name: sys.name,
                status: false,
                view_state: false,
                checked: false
            }))
        },

        handleSysCheck (data) {
            const index = this.PB.sysOrganizations.findIndex(
                it => it.sys_id === data.sys_id
            )
            if (index >= 0) this.$set(this.PB.sysOrganizations, index, data)
        },

        async handleSave () {
            const loadingRef = this.$loading({
                target: ".organization-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const {id, name, pid, sysOrganizations} = this.PB;
                const res = await this.$axios({
                    url: "/api/org/addOrg",
                    method: "post",
                    data: {
                        id, name, pid,
                        sysOrgList: JSON.stringify(sysOrganizations.filter(
                            it => it.checked
                        ).map(
                            it => ({
                                sysId: it.sys_id,
                                viewState: it.view_state ? 1 : 0,
                                status: it.status ? 1 : 0
                            })
                        )) 
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.viewIsReady = false;
            this.PB = {
                name: "",
                pid: "",
                sysOrganizations: []
            };
            this.parent = {};
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped></style>